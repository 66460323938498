<template>
  <article v-loading="loading">
    <RouterNav :name="name"></RouterNav>
    <div class="class-exam-detail">
      <div class="content-info">
        <div class="info-test-name">
          <span>{{ classExamInfo.testName ? classExamInfo.testName : '' }}</span>
          <div class="excel-btn" @click="exportGradeTable">
            <div class="icon"></div>
            <div>导出成绩表</div>
          </div>
          <!--<el-button class="btn" type="primary" @click="exportGradeTable">导出成绩表</el-button>-->
        </div>
        <div class="info-desc info-info">
          <span>{{ classExamInfo.gradeName }}</span>
          <span style="margin-right: 14px;">{{ classExamInfo.clazzName }}</span>
          <span style="margin: 0 14px;">{{ classExamInfo.count }}/{{ classExamInfo.totalCount }}人</span>
          <span>{{ setShow(classExamInfo.avgScore) }}/{{ classExamInfo.totalScore }}分</span>
        </div>
        <div class="info-desc date-start">考试时间：{{ classExamInfo.dateStart | trimT }}</div>
        <div class="info-desc paper-group">试题：{{ classExamInfo.paperGroupName }}</div>
      </div>
      <div class="content-detail">
        <el-tabs v-model="activeName" @tab-click="changeActive">
          <el-tab-pane label="学生情况" name="studentSituation">
            <div class="student-desc">
              <el-select v-model="groupId" v-if="Array.isArray(batchGroupList) && batchGroupList.length > 1" class="selected-test" placeholder="请选择" style="margin-bottom: 0;" >
                <el-option label="全部试题成绩" value=""></el-option>
                <el-option  v-for="item in batchGroupList"  :key="item.groupId"  :label="item.paperGroupName"  :value="item.groupId"></el-option>
              </el-select>
              <span class="statistics">本次考试：<span class="population">{{ absentStudentList.length }}</span>人缺考，<span class="population">{{ flunkStudentList.length }}</span>人不合格</span>
              <a class="check-list" @click="checkList">查看名单</a>
            </div>
            <div>
              <el-table
                :key="studentTableKey"
                :data="studentList"
                border
                show-overflow-tooltip
                header-row-class-name="table-header-row"
                style="width: 100%"
                ref="table"
                class="table-student-list"
              >
                <el-table-column fixed type="index" label="排名" align="center" header-align="center" width="60"/>
                <el-table-column fixed prop="examineeName" label="考生姓名" align="center" header-align="center" width="100"/>
                <el-table-column fixed prop="examineeNum" label="考号" align="center" header-align="center" width="130"/>
                <el-table-column fixed prop="score" label="总成绩" align="center" header-align="center" width="100">
                  <template slot-scope="{ row }">
                    <span class="score" :class="row.accuracyRate >= 85 ? 'green' : (85 > row.accuracyRate && row.accuracyRate >= 60 ? 'yellow' : '')">
                      <span v-if="row.absent == 0">{{ setShow(row.score) }}</span>
                      <span v-else>缺考</span>
                    </span>
                  </template>
                </el-table-column>
                <template v-if="questionTypeList">
                  <template v-for="(question, questionKey) in questionTypeList">
                    <el-table-column :key="questionKey" :label="question.questionTypeName" align="center" header-align="center">
                      <template v-for="item in question.itemList">
                        <el-table-column
                            v-if="item.itemName !== '总成绩'"
                            :key="item.itemName"
                            :prop="item.itemScore.toString()"
                            :label="item.itemName"
                            align="center"
                            header-align="center"
                            width="100">
                          <template slot-scope="{ row }">
                            <template v-if="row.questionTypeList">
                              <template v-for="el in row.questionTypeList">
                            <span :key="el.questionTypeName" v-if="el.questionTypeName === question.questionTypeName">
                              <template v-if="el.itemList">
                                <template v-for="i in el.itemList">
                                  <span :key="i.itemName" v-if="i.itemName === item.itemName">{{ setShow(i.itemScore) || '-' }}</span>
                                </template>
                              </template>
                              <template v-else>-</template>
                            </span>
                              </template>
                            </template>
                            <template v-else>-</template>
                          </template>
                        </el-table-column>
                      </template>
                    </el-table-column>
                  </template>
                </template>
                <el-table-column fixed="right" label="操作" align="center" header-align="center" width="134">
                  <template slot-scope="scope">
                    <a v-if="scope.row.absent == 0" class="check-report"  @click="checkReport(scope.row)">查看报告</a>
                    <span v-else> </span>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                class="table-pagination"
                :current-page.sync="pageIndex"
                :page-count="pageCount"
                background
                layout="prev, pager, next"
                :total="totalCount"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane label="班级报告" name="classReport">
            <div class="class-desc">
              <el-table
                :key="classTableKey"
                :data="clazzStatistics"
                border
                style="width: 100%"
                header-row-class-name="class-header-row"
                row-class-name="class-row"
              >
                <el-table-column align="center" header-align="center" label="考试人数" width="173">
                  <template slot-scope="scope">
                    <span>{{ scope.row.count }}</span>/<span>{{ scope.row.totalCount }}</span>人
                  </template>
                </el-table-column>
                <el-table-column align="center" header-align="center" prop="avgScore" label="平均分" width="174">
                  <template slot-scope="{ row }">{{ setShow(row.avgScore) }}分</template>
                </el-table-column>
                <el-table-column align="center" header-align="center" prop="maxScore" label="最高分" width="174">
                  <template slot-scope="{ row }">{{ setShow(row.maxScore) }}分</template>
                </el-table-column>
                <el-table-column align="center" header-align="center" prop="minScore" label="最低分" width="174">
                  <template slot-scope="{ row }">{{ setShow(row.minScore) }}分</template>
                </el-table-column>
                <el-table-column align="center" header-align="center" prop="excellentRate" label="优秀率(≥85%)" width="174">
                  <template slot-scope="{ row }">
                    {{ setShow(row.excellentRate) }}%
                  </template>
                </el-table-column>
                <el-table-column align="center" header-align="center" prop="badRate" label="低分率(<60%)" width="174">
                  <template slot-scope="{ row }">
                    {{ setShow(row.badRate) }}%
                  </template>
                </el-table-column>
              </el-table>
              <div class="item-title">得分区间分布</div>
              <div class="row">
                <div class="left">
                  <template v-for="(item, index) in scoreStatisticsList">
                    <el-radio v-model="selectPattern" :label="index" :key="item.id" style="margin-right: 10px;">{{item.questionsTypeName}}</el-radio>
                  </template>
                </div>
                <div class="tips">（点击蓝色柱状图可查看对应学生名单）</div>
              </div>
              <div ref="echarts" :style="{width: '1044px', height: '360px'}"></div>
              <div v-if="nameStr !== null" class="student-list">{{ nameStr }}</div>
              <div class="item-title">题型统计</div>
              <el-table
                :key="questionTableKey"
                :data="questionTypeStatisticsList"
                ref="questionTable"
                border
                style="width: 100%"
                header-row-class-name="class-header-row"
                row-class-name="class-row"
              >
                <el-table-column fixed align="center" header-align="center" prop="questionsTypeName" label="题型" width="149"></el-table-column>
                <el-table-column align="center" header-align="center" prop="avgScore" label="平均分" width="149">
                  <template slot-scope="{ row }">{{ setShow(row.avgScore) }}分</template>
                </el-table-column>
                <el-table-column align="center" header-align="center" prop="badRate" label="得分率" width="149">
                  <template slot-scope="{ row }">{{ setShow(row.scoreRate) }}%</template>
                </el-table-column>
                <el-table-column align="center" header-align="center" prop="maxScore" label="最高分" width="149">
                  <template slot-scope="{ row }">{{ setShow(row.maxScore) }}分</template>
                </el-table-column>
                <el-table-column align="center" header-align="center" prop="minScore" label="最低分" width="149">
                  <template slot-scope="{ row }">{{ setShow(row.minScore) }}分</template>
                </el-table-column>
                <el-table-column align="center" header-align="center" prop="excellentRate" label="优秀率(≥85%)" width="149">
                  <template slot-scope="{ row }">{{ setShow(row.excellentRate) }}%</template>
                </el-table-column>
                <el-table-column align="center" header-align="center" prop="badRate" label="低分率(<60%)" width="149">
                  <template slot-scope="{ row }">{{ setShow(row.badRate) }}%</template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="考试内容" name="examination">
            <div>
              <el-select v-model="examinationGroupId" class="selected-group" placeholder="请选择" v-if="Array.isArray(batchGroupList) && batchGroupList.length > 1">
                <el-option
                    v-for="item in batchGroupList"
                    :key="item.groupId"
                    :label="item.paperGroupName"
                    :value="item.groupId">
                </el-option>
              </el-select>
              <iframe
                id="iframe"
                class="iframe"
                :class="Array.isArray(batchGroupList) && batchGroupList.length > 1 ? 'default' : ''"
                :src="iframeSrc"
                v-if="activeName === 'examination' && show"
              ></iframe>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 查看名单 -->
    <el-dialog :visible.sync="dialogVisible" width="450px">
      <span slot="title" class="dialog-title">
        <span>查看名单</span>
      </span>
      <div id="listContent">
        <div class="content-item mb-20">
          <div class="item-title">考试名称：</div>
          <div>{{ classExamInfo.testName }}</div>
        </div>
        <div class="content-item mb-20">
          <div class="item-title">考试时间：</div>
          <div>{{ classExamInfo.dateStart | trimT }}</div>
        </div>
        <div v-if="Array.isArray(absentStudentList) && absentStudentList.length > 0" class="content-item mb-20">
          <div class="item-title">缺考学生：</div>
          <div>{{ absentStudentList.join('、')}}</div>
        </div>
        <div v-if="Array.isArray(flunkStudentList) && flunkStudentList.length > 0" class="content-item mb-20">
          <div class="item-title">不及格学生：</div>
          <div>{{ flunkStudentList.join('、')}}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" style="text-align: center;display: block;">
        <el-button class="btn" type="primary" @click="contentCopy">一键复制</el-button>
      </span>
    </el-dialog>
  </article>
</template>

<script>
import RouterNav from '@/components/RouterNav.vue'
import {
  getStudentList,
  getTotalStatistics,
  exportGradeTable,
  getGroupListByBatchNum,
  getNormalOfficialList
} from '@/api/official'
import echarts from 'echarts'

export default {
  name: 'ClassExamDetail',
  components: {
    RouterNav
  },
  data() {
    return {
      loading: false,
      classExamInfo: {},
      name: '查看报告',
      activeName: 'studentSituation',
      selectPaneName: [],
      studentList: [],
      allStudentList: [],
      absentStudentList: [],
      flunkStudentList: [],
      clazzStatistics: [],
      scoreStatisticsList:  [],
      questionTypeStatisticsList:  [],
      questionTypeList:  [],
      selectPattern: 0,
      options: [
        { value: '选项1', label: '黄金糕' },
        { value: '选项2', label: '双皮奶' },
        { value: '选项3', label: '蚵仔煎' },
        { value: '选项4', label: '龙须面' },
        { value: '选项5', label: '北京烤鸭' }
      ],
      value: '',
      dialogVisible: false,
      nameStrArr: [],
      nameStr: null,
      iframeSrc: '',
      batchGroupList: [],
      batchGrouMap: {},
      groupId: '',
      examinationGroupId: '',
      show: true,
      studentTableKey: 0,
      classTableKey: 1,
      questionTableKey: 2,
      totalCount: 0,
      pageCount: 0,
      pageIndex: 1,

    }
  },
  created() {
    const classExamInfo = JSON.parse(sessionStorage.getItem('classExamDetail'))
    if (!classExamInfo || Object.keys(classExamInfo).length === 0) {
      // 通过列表接口查询考试的详情
      this.getClassExamInfoByList()
    } else {
      this.classExamInfo = classExamInfo
      this.getGroupListByBatchNumTest()
    }
  },
  watch: {
    selectPattern(val, old) {
      if (val !== old) {
        this.setChartsData(val)
      }
    },
    groupId() {
      this.getStudentList()
    },
    examinationGroupId(val) {
      this.show = false
      this.iframeSrc = `${this.$urls.iframeSrc}?groupId=${val}&batchNum=${this.classExamInfo.batchNum}&classId=${this.classExamInfo.clazzId}`
      this.show = true
      this.$forceUpdate()
    }
  },
  methods: {
    getClassExamInfoByList() {
      const dataParam = {}
      const pageIndex = 1
      const pageSize = 10
      if (this.$route.query.classId){
        dataParam.clazzId = this.$route.query.classId
      }
      getNormalOfficialList(dataParam, pageIndex, pageSize).then(res => {
        if (res.state === '11') {
          const {pageContents} = res.data
          if (pageContents && pageContents.length){
            for(let i = 0; i< pageContents.length; i++){
              const classExamInfo = pageContents[i]
              if (classExamInfo.clazzId === this.$route.query.classId){
                this.classExamInfo = classExamInfo
                sessionStorage.setItem('classExamDetail', JSON.stringify(classExamInfo))
                break
              }
            }
          }
        }
        // 如果是找不到对应的考试的话，则跳到首页
        if(!this.classExamInfo){
          this.$router.replace({name: 'home'})
          return
        } else {
          this.getGroupListByBatchNumTest()
        }
      }).catch (e => {
        console.error('获取考试详情异常', e)
      })
    },
    // 根据考试批次号查询试题ID和试题名称
    async getGroupListByBatchNumTest() {

      const data = {
        batchNum: this.classExamInfo.batchNum,
        clazzId: this.classExamInfo.clazzId
      }
      const res = await getGroupListByBatchNum(data)
      if (res.state === '11') {
        this.batchGroupList = res.data || []
        if (Array.isArray(res.data) && res.data.length > 0) {
          res.data.forEach(item => {
            this.batchGrouMap[item.groupId] = item.paperGroupName
          })
          if (res.data.length > 1){
            this.groupId = ''
          } else {
            this.groupId = this.batchGroupList[0].groupId
          }
          this.getStudentList()
        }
      }
    },
    // 获取学生列表
    async getStudentList() {
      this.loading = true
      try {
        const data = {
          batchNum: this.classExamInfo.batchNum,
          clazzId: this.classExamInfo.clazzId,
        }
        if (this.groupId) {
          if (Array.isArray(this.batchGroupList) && this.batchGroupList.length > 1){
            data.groupId = this.groupId
          }
        }
        const res = await getStudentList(data)
        if (res.state === '11') {
          this.selectPaneName.push('studentSituation')
          this.setStudentList(res.data.studentList)
          this.absentStudentList = res.data.absentStudentList || []
          this.flunkStudentList = res.data.flunkStudentList || []
        }
      } catch (e) {
      }
      this.loading = false
    },
    // 处理学生列表数据（动态列）
    setStudentList(list) {
      if (Array.isArray(list) && list.length > 0) {
        list.forEach(item => {
          let questionTypeList = item.questionTypeList || []
          if (Array.isArray(questionTypeList)&& questionTypeList.length > 0){
            item.typeScoreList = {}
            questionTypeList.forEach(el => {
              item.typeScoreList[el.questionTypeName] = el
            })
          }
        })
        this.questionTypeList = list[0].questionTypeList
        this.totalCount = list.length || 0
        this.pageCount = Math.ceil((list.length/10)) || 0
        this.allStudentList = list || []
        this.studentList = this.getPageStudentList(this.pageIndex)
      } else {
        this.totalCount =  0
        this.pageCount = 0
        this.allStudentList = []
        this.studentList = []
      }
      this.$nextTick(() =>{
        this.$refs.table.doLayout()
      })
    },
    // 设置当前页学生数据
    getPageStudentList(pageIndex) {
      let studentList = []
      this.allStudentList.forEach((item, itemKey) => {
        if (itemKey >= (pageIndex - 1) * 10 && itemKey < pageIndex * 10) {
          studentList.push(item)
        }
      })
      return studentList
    },
    // 学生列表分页切换
    handleCurrentChange(val) {
      this.pageIndex = val
      this.studentList = this.getPageStudentList(this.pageIndex)
    },
    // 获取班级报告
    async getTotalStatistics() {
      this.loading = true
      const data = {
        batchNum: this.classExamInfo.batchNum,
        clazzId: this.classExamInfo.clazzId
      }
      const res = await getTotalStatistics(data)
      this.loading = false
      if (res.state === '11') {
        this.selectPaneName.push('classReport')
        this.clazzStatistics = [res.data.clazzStatistics]
        this.scoreStatisticsList = res.data.scoreStatisticsList
        this.questionTypeStatisticsList = res.data.questionTypeStatisticsList
        if (Array.isArray(this.scoreStatisticsList) && this.scoreStatisticsList.length > 0) {
          this.setChartsData(this.selectPattern)
        }
      }
    },
    // 设置图表数据
    setChartsData(index) {
      let scoreStatistics = this.scoreStatisticsList[index]
      this.nameStrArr[index] = []
      let data = []
      let series = []
      const obj = {
        name: scoreStatistics.questionsTypeName,
        type: 'bar',
        data: [],
        label: {
          show: true,
          position: 'top',
          formatter: function(params) {
            return `${params.data.count}人`
          }
        },
      }
      if (Array.isArray(scoreStatistics.scoreChartList) && scoreStatistics.scoreChartList.length > 0) {
        scoreStatistics.scoreChartList.forEach(score => {
          data.push(score.scoreRange)
          obj.data.push({
            name: score.examineeName,
            value: score.countRate,
            count: score.count,
            scoreRange: score.scoreRange
          })
          this.nameStrArr[index].push(score.examineeName)
        })
      }
      series.push(obj)
      this.initCharts(series, data)
    },
    // 初始化图表
    initCharts (series, data) {
      const echarts = require('echarts')
      this.myChart = echarts.init(this.$refs.echarts)
      const that = this
      this.myChart.setOption({
        color: ['#309AF2'],
        tooltip: {
          trigger: 'item',
          confine: true,
          triggerOn: 'click',
          formatter (params) {
            return `${params.seriesName}<br />分数段${params.data.scoreRange}，人数占比${params.value.toFixed(1)}%`
          }
        },
        grid: {
          left: '5%'
        },
        xAxis: {
          name: '得分率',
          axisLabel: {
            show: true,
            textStyle: {
              color: '#666'
            },
            emphasis: {
              color: '#fff'
            }
          },
          data
        },
        yAxis: {
          name: '人数占比(%)',
          max: 100,
          min: 0
        },
        series
      }, true)
      this.myChart.on('click', (params) => {
        const nameStr = this.nameStrArr[this.selectPattern][params.dataIndex]
        this.nameStr = nameStr ? nameStr : ''
      })
    },
    // 导出成绩表
    async exportGradeTable() {
      const h = this.$createElement
      let checked = false
      const random = Math.random()
      const superAdmin = sessionStorage.getItem('superAdmin')
      const schoolSection = sessionStorage.getItem('schoolSection')
      const localProvinceId = sessionStorage.getItem('schoolProvinceId')
      const isSuperTeacher = superAdmin === '2'
      const provinceId = isSuperTeacher ? String(this.$route.query.provinceId) : localProvinceId
      const section = isSuperTeacher ? String(this.$route.query.section) : schoolSection
      await this.$confirm('确认导出成绩吗？', '提示', {
        message: h('div', null, [
          h('p', null, '确认导出成绩吗？'),
          (provinceId === '440000' && section === '2' ? h('ElCheckbox', {
            style: 'position: absolute;left: 6px;bottom: -30px;',
            key: random,
            props: {
              checked: checked
            },
            on: {
              change(val) {
                checked = val
              }
            }
          }, '导出转换为20分制') : ''),
        ]),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      const convertScore = checked ? 1 : 0
      checked = false
      const data = {
        examId: this.classExamInfo.examId,
        clazzId: this.classExamInfo.clazzId,
        convertScore
      }
      const res = await exportGradeTable(data)
      const blob = new Blob([res.data], {type: 'application/vnd.ms-excel;charset=utf-8'})
      const objectUrl = URL.createObjectURL(blob)
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      a.href = objectUrl
      a.download = `${this.classExamInfo.testName}成绩统计表.xls`
      a.click()
      document.body.removeChild(a)
    },
    // 切换 tab 页
    changeActive(tab) {
      let flag = !this.selectPaneName.includes(tab.name)
      switch (tab.name) {
        case 'studentSituation':
          if (flag) this.getStudentList()
            this.studentTableKey = Math.random()*100
            this.$refs.table.doLayout()
          break
        case 'classReport':
          if (flag) this.getTotalStatistics()
          this.$refs.questionTable.doLayout()
          this.classTableKey = Math.random()*100 + 1
          this.questionTableKey = Math.random()*100 + 2
          break
        case 'examination':
          if (flag) {
            this.examinationGroupId = this.batchGroupList[0].groupId
            this.selectPaneName.push('examination')
          }
          break
        default:
          break
      }
    },
    // 查看名单
    checkList() {
      this.dialogVisible = true
    },
    // 查看报告
    checkReport(row) {
      sessionStorage.setItem('studentReport', JSON.stringify(row))
      this.$router.push({
        name: 'studentReport',
        query: {
          examineeId: row.examineeId,
          batchNum: this.classExamInfo.batchNum,
          clazzId: this.classExamInfo.clazzId
        }
      })
    },
    // 一键复制
    contentCopy () {
      // 创建range对象
      const range = document.createRange();
      // 选择节点（即选中我们要复制的内容，这里是查看名单弹框的内容，在id为‘listContent’ 的DOM节点）
      range.selectNode(document.getElementById('listContent'));
      // 创建一个 Selection 对象，表示用户选择的文本范围或光标的当前位置
      const selection = window.getSelection();
      // 判断选区中range对象数量（这里指整个window），当有选中时清除所有选中（预防复制多其他东西）
      if (selection.rangeCount > 0) selection.removeAllRanges();
      // 往election 对象里添加指定的选中内容
      selection.addRange(range);
      // 复制到选中内容到剪贴板
      document.execCommand('copy')
      // 清除选中
      selection.removeAllRanges();
      this.$message({ message: '复制成功', type: 'success' })
    },
    // 设置小数点
    setShow(score){
      return (typeof score === "number" ? score.toFixed(1) : score)
    }
  }
}
</script>

<style lang="scss" scoped>
.class-exam-detail{
  .content-info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    padding: 20px 28px;
    .info-test-name{
      font-size: 20px;
      color: #333333;
      line-height: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &>span{
        font-weight: bold;
      }
      .btn{
        &:hover{
          background-color: #309AF2;
        }
      }
    }
    .info-desc{
      font-size: 15px;
      line-height: 21px;
      margin-top: 10px;
      &.info-info{
        color: #333333;
      }
      &.date-start,&.paper-group{
        color: #999999;
      }
      &.paper-group{
        width: 1044px;
        text-align: justify;
        text-justify: newspaper;
        word-break: break-all;
      }
    }
  }
  .content-detail{
    margin-top: 10px;
    padding: 10px 28px;
    background-color: #fff;
    .population{
      color: #FF3C30;
      font-size: 17px;
    }
    a{
      text-decoration: underline;
      color: #309AF2;
      &:visited{
        color: #309AF2;
      }
    }
    .student-desc{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 14px;
      .selected-test{
        //flex: 1;
        width: 340px;
        margin-bottom: 15px;
      }
      .statistics{
        flex: 1;
        text-align: right;
        font-size: 17px;
        color: #333333;
      }
      .check-list{
        margin-left: 14px;
      }
    }
    .table-pagination{
      text-align: center;
      padding-top: 12px;
    }
    .row {
      @include flex(space-between);
      //padding: 0 28px;
      //.left {
      //  label {
      //    margin-right: 28px;
      //  }
      //}
      .tips {
        font-size: 15px;
        color: #999999;
      }
    }
    .student-list{
      padding: 20px;
      background-color: #F6F6F6;
      color: #333333;
      font-size: 17px;
      border-radius: 16px;
    }
    .item-title{
      height: 66px;
      line-height: 24px;
      position: relative;
      padding: 20px 0;
      font-size: 17px;
      color: #333333;
      //border-left: 1px solid #309AF2;
      &:after{
        content: '';
        height: 20px;
        width: 4px;
        background-color: #309AF2;
        position: absolute;
        left: -28px;
        top: 22px;
      }
    }
    .selected-group{
      margin-bottom: 15px;
    }
    .iframe{
      width: calc(100% + 56px);
      height: calc(100vh - 380px);
      margin-left: -28px;
      &.default{
        height: calc(100vh - 427px);
      }
    }
  }
}
::v-deep .el-tabs__content{
  overflow: unset;
}
::v-deep .el-table{
  // 解决学生列表固定列定位错位，原因未明
  &.table-student-list{
    .el-table__body-wrapper{
      height: calc(100% - 83px) !important;
    }
    .el-table__fixed,
    .el-table__fixed-right{
      .el-table__fixed-body-wrapper{
        top: 82px !important;
        height: calc(100% - 83px) !important;
      }
    }
  }
  &.el-table--scrollable-x{
    .el-table__fixed,
    .el-table__fixed-right{
      height: calc(100% - 17px) !important;
    }
  }
  //&.el-table--scrollable-y{
  //  .el-table__fixed-right{
  //    right: 17px !important;
  //  }
  //  .el-table__fixed-right-patch{
  //    width: 17px !important;
  //  }
  //}
  .cell{
    font-size: 15px;
  }
  .score{
    color: #FF3C30;
    &.green{
      color: #1DC060;
    }
    &.yellow{
      color: #FFA516;
    }
  }
  /* element 表格滚动条相关样式  */
  /* ---------- 开始 ---------- */
  .el-table__body-wrapper{
    // 滚动条区域
    &::-webkit-scrollbar{
      width: 16px; // 竖向滚动条宽度
      height: 16px; // 横向向滚动条高度
      background-color: #FFFFFF;
    }
    // 滚动条里的滑块
    &::-webkit-scrollbar-thumb{
      border-radius: 16px;
      background-color: #DEDEDE;
    }
    // 滚动条里的滑块滑动的槽
    &::-webkit-scrollbar-track{
      border-radius: 16px;
      background-color: #FFFFFF;
    }
  }
  /* ---------- 结束 ---------- */
  .table-header-row{
    th{
      background-color: #F6F6F6;
      &.is-leaf{
        border-bottom: 1px solid #EBEEF5;
      }
    }
  }
  .class-header-row{
    th{
      background-color: #F6F6F6;
      .cell{
        font-size: 15px;
        color: #666666;
        line-height: 21px;
      }
      //&.is-leaf{
      //  border-bottom: 1px solid #EBEEF5;
      //}
    }
  }
  .el-table__row.class-row{
    td{
      .cell{
        font-size: 15px;
        color: #333333;
        line-height: 21px;
      }
    }
  }
}
.el-table--border{
  border-top: 1px solid #EBEEF5;
  border-left: 1px solid #EBEEF5;
}
::v-deep .el-dialog__wrapper{
  .el-dialog{
    overflow: hidden;
    border-radius: 15px;
    .el-dialog__header{
      height: 78px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #E5E5E5;
      padding: 25px 28px;
      .dialog-title{
        &>span{
          font-size: 20px;
          color: #333333;
        }
      }
      .el-dialog__headerbtn{
        width: 50px;
        height: 50px;
        top: 15px;
        right: 10px;
        font-size: 20px;
        border-radius: 25px;
        i{
          color: #333333;
        }
        &:hover{
          background-color: #F6F6F6;
          color: #333333;
        }
      }
    }
    .el-dialog__body{
      padding: 20px 28px;
      .content-item{
        font-size: 16px;
        color: #333333;
        line-height: 22px;
        .item-title{
          font-size: 17px;
          line-height: 24px;
          font-weight: bold;
        }
      }
    }
    .btn{
      width: 160px;
      height: 40px;
      background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);
      border: none;
      font-size: 15px;
      &:hover{
        background: #309AF2;
      }
    }

  }
}
.mr-10{
  margin-right: 10px !important;
}
.mb-20{
  margin-bottom: 20px;
}
.btn{
  //width: 160px;
  //height: 40px;
  background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);
  border: none;
  font-size: 15px;
  &:hover{
    background: #309AF2;
  }
}
.excel-btn {
  @include flex;
  height: 34px;
  padding: 0 20px;
  border: 1px solid #E5E5E5;
  border-radius: 17px;
  font-size: 15px;
  cursor: pointer;
  &:hover {
    background-color: #F6F6F6;
  }
  .icon {
    @include icon(url("../../assets/excel.png"), 26px, 26px);
    margin-right: 6px;
  }
}

</style>
